import {InjectionToken} from '@angular/core'

/**
 * Defines and exports an injection token that replaces the browser
 * file reader.
 *
 * FILE_READER is just name, it could be FOO_BAR
 * `export` is to make it available outside this file
 * `const` is just to declare that it is not supposed to be overridden.
 *
 * InjectionToken is what we want to achieve (we want to inject what ever)
 *
 * <FileReader> just tells what we _intend_ to return. In this case it is the
 * browser (dom) fileReader
 *
 * The 'fileReader' is just a name for debugging can be anything.
 */
export const FILE_READER = new InjectionToken<FileReader>('fileReader')
