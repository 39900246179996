import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable, map} from 'rxjs'
import {environment} from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(private http: HttpClient) {
  }

  public getImage(name: string): Observable<string> {
    const url = `${environment.cmsUrl}/${name}`
    return this.http.get(url, {responseType: 'blob'}).pipe(
      map((res: Blob) => {
        return URL.createObjectURL(res)
      })
    )
  }

  public getText(name: string): Observable<any> {
    const url = `${environment.cmsUrl}/legal/${name}.html`
    return this.http.get(url, {responseType: 'text'})
  }
}
