import {Injectable, Inject} from '@angular/core'
import {Observable, of, filter, ReplaySubject, tap, BehaviorSubject} from 'rxjs'
import {LOCAL_STORAGE, SESSION_STORAGE} from '../application/storage.provider'
import {Process} from '../model/process'

const REMEMBER_ME_TOKEN = 'jhc-remember-me'
const CASE_TOKEN = 'jhc-case'

export interface IConditions {
  type: 'person' | 'business' | undefined

  accepted: boolean

  email: string | undefined
}

@Injectable({
  providedIn: 'root'
})
export class BootstrapService {

  /**
   * Read and written from all sorts of places
   */
  public conditions$: ReplaySubject<IConditions> = new ReplaySubject<IConditions>(1)

  public conditionsAccepted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  public remember$: Observable<boolean>

  private _remember$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1)

  private storage: Storage

  constructor(
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
    @Inject(SESSION_STORAGE) private sessionStorage: Storage
  ) {
    this.storage = sessionStorage
    this.remember$ = this._remember$.asObservable()

    this.remember$
      .pipe(
        tap(() => {
          this.storage = localStorage
        }),
        filter((remember: boolean) => !remember))
      .subscribe({
        next: () => {
          this.storage = this.sessionStorage
          this.localStorage.removeItem(CASE_TOKEN)
        }
      })

    this.conditions$.pipe().subscribe({
      next: (conditions: IConditions) => {
        this.conditionsAccepted$.next(conditions.accepted)
      }
    })
  }

  public bootstrap(): Observable<any> {
    this.checkRememberStatus()
    return of({})
  }

  public saveProcess(process: Process): Observable<Process> {
    this.storage.setItem(CASE_TOKEN, JSON.stringify(process))
    return of(process)
  }

  public getProcess(): Observable<Process> {
    const processString = this.storage.getItem(CASE_TOKEN)
    if (processString) {
      return of(new Process(JSON.parse(processString)))
    }
    return of(new Process({} as any))
  }

  public setRemember(remember: boolean): void {
    this.localStorage.setItem(REMEMBER_ME_TOKEN, remember + '')
    this._remember$.next(remember)
  }

  private checkRememberStatus(): void {
    const setting = this.localStorage.getItem(REMEMBER_ME_TOKEN)
    if (setting && setting === 'true') {
      this.storage = this.localStorage
      this._remember$.next(true)
    }
  }
}
