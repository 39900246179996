<mat-radio-group
  (change)="processService.setConditions($event)"
  aria-label="Vem företräder du?"
  class="conditions"
  color="primary">
  <mat-radio-button [checked]="type === 'person'" value="terms"><span class="radio-label">Jag företräder mig själv - <a
    [routerLink]="['/', 'conditions', 'terms']">Villkor</a></span></mat-radio-button>
  <mat-radio-button [checked]="type === 'business'" value="business">
    <span class="radio-label">Jag företräder ett företag - <a
      [routerLink]="['/', 'conditions', 'terms']">Villkor</a></span>
  </mat-radio-button>
</mat-radio-group>

