<div class="main">
  <div class="header">
    <router-outlet name="header"></router-outlet>
  </div>

  <div class="jhc-filler"></div>
  <router-outlet></router-outlet>

  <div>
    <router-outlet name="extra"></router-outlet>
  </div>

  <div class="jhc-filler"></div>

  <div class="footer">
    <router-outlet name="footer"></router-outlet>
  </div>
</div>
