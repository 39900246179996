import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {StartComponent} from './start/start.component'
import {InfoComponent} from './info/info.component'
import {HeaderComponent} from './header/header.component'
import {FooterComponent} from './footer/footer.component'
import {ConditionsComponent} from './conditions/conditions.component'

const routes: Routes = [
  {
    path: '',
    component: StartComponent,
    children: [
      {
        path: '',
        redirectTo: 'main',
        pathMatch: 'full'
      },
      {
        path: 'conditions/:type',
        component: ConditionsComponent
      },
      {
        path: 'main',
        loadChildren: () => import('../1-create/create.module').then(m => m.CreateModule),
      },
      {
        path: 'verify',
        loadChildren: () => import('../2-verify/verify.module').then(m => m.VerifyModule),
      },
      {
        path: 'sign',
        loadChildren: () => import('../3-sign/sign.module').then(m => m.SignModule),
      },
      {
        path: 'test',
        loadChildren: () => import('../9-test/test.module').then(m => m.TestModule)
      },
      {
        path: 'info',
        component: InfoComponent,
        outlet: 'extra'
      },
      {
        path: 'header',
        component: HeaderComponent,
        outlet: 'header'
      },
      {
        path: 'footer',
        component: FooterComponent,
        outlet: 'footer'
      }
    ]
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, enableTracing: false})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
