<div class="holder">
  <div [routerLink]="['/', {outlets: {primary: null, extra: null}}]" skipLocationChange="true"
       class="logo"
  ><img alt="logo" src="/assets/logo.svg">
  </div>
  <div class="jhc-filler"></div>
  <div>
    <button [matMenuTriggerFor]="menu" mat-button>
      <mat-icon class="material-symbols-sharp menu-icon">menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <div>
        <button
          [routerLink]="['/', {outlets: {primary: ['main'], footer: ['footer'], header: ['header']}}]"
          mat-menu-item>Start
        </button>
        <button
          [routerLink]="['/', 'conditions', 'privacy']"
          mat-menu-item>Integritetspolicy
        </button>
        <button
          [routerLink]="['/', 'conditions', 'terms']"
          mat-menu-item>Villkor
        </button>
        <button
          [routerLink]="['/', 'conditions', 'faq']"
          mat-menu-item>FAQ
        </button>
        <div mat-menu-item>
          <mat-checkbox
            (change)="bootstrapService.setRemember($event.checked)"
            [checked]="bootstrapService.remember$ | async"
            color="primary">Kom ihåg mig
          </mat-checkbox>
        </div>
      </div>
    </mat-menu>
  </div>
</div>
