import {Component} from '@angular/core'
import {BootstrapService} from '../../services/bootstrap.service'

@Component({
  selector: 'jhc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  constructor(public bootstrapService: BootstrapService) {
  }
}
