import {Component, OnInit} from '@angular/core'
import {Location} from '@angular/common'
import {ActivatedRoute, ParamMap} from '@angular/router'
import {ImageService} from '../../services/image.service'
import {switchMap} from 'rxjs'

@Component({
  selector: 'jhc-conditions',
  templateUrl: './conditions.component.html',
  styleUrls: ['./conditions.component.scss']
})
export class ConditionsComponent implements OnInit {

  public type: 'terms' | 'privacy' | 'faq' = 'privacy'

  public content = '<h2>Hämtar...</h2>'

  constructor(
    public location: Location,
    private route: ActivatedRoute,
    private imageService: ImageService
  ) {
  }

  public ngOnInit(): void {
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          this.type = params.get('type') as any
          return this.imageService.getText(this.type)
        })
      )
      .subscribe({
        next: (html: string) => this.content = html
      })
  }
}
