import {Component, OnInit} from '@angular/core'
import {ProcessService} from '../../services/process.service'

@Component({
  selector: 'jhc-accept-conditions',
  templateUrl: './accept-conditions.component.html',
  styleUrls: ['./accept-conditions.component.scss']
})
export class AcceptConditionsComponent implements OnInit {
  public type: 'person' | 'business' | undefined

  constructor(
    public processService: ProcessService,
  ) {
  }

  public ngOnInit(): void {
    this.processService.resetConditions()
  }
}
