import {Component, Input, Output, EventEmitter} from '@angular/core'
import {IDocumentListItem} from '../../services/document.service'

@Component({
  selector: 'jhc-selected',
  templateUrl: './selected.component.html',
  styleUrls: ['./selected.component.scss']
})
export class SelectedComponent {
  @Input() document: IDocumentListItem = {} as any

  @Input() removable: boolean = true

  @Output() remove: EventEmitter<string> = new EventEmitter<string>()

}
