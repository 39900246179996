import {NgModule, APP_INITIALIZER} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {HttpClientModule} from '@angular/common/http'
import {FILE_READER} from '../application/file-reader.provider'
import {StartComponent} from './start/start.component'
import {InfoComponent} from './info/info.component'
import {HeaderComponent} from './header/header.component'
import {FooterComponent} from './footer/footer.component'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {MatCheckboxModule} from '@angular/material/checkbox'
import {MatMenuModule} from '@angular/material/menu'
import {MatIconModule} from '@angular/material/icon'
import {MatButtonModule} from '@angular/material/button'
import {VerifyModule} from '../2-verify/verify.module'
import {BootstrapService} from '../services/bootstrap.service'
import {sessionStorageProvider, localStorageProvider} from '../application/storage.provider'
import {ConditionsComponent} from './conditions/conditions.component'
import {JHCCommonModule} from '../common/common.module'
import {windowProvider} from '../application/window.provider'

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    InfoComponent,
    HeaderComponent,
    FooterComponent,
    ConditionsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    VerifyModule,
    JHCCommonModule
  ],
  providers: [
    {provide: FILE_READER, useClass: FileReader},
    windowProvider,
    sessionStorageProvider,
    localStorageProvider,
    BootstrapService,
    {
      // This is nice magic to make sure we load any existing tokens.
      // We return an observable, the "angulars" will subscribe to this
      // and when it completes, it will continue.
      provide: APP_INITIALIZER,
      useFactory: (s: BootstrapService) => () => s.bootstrap(),
      deps: [BootstrapService],
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
