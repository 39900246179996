import {Component, OnInit} from '@angular/core'
import {ProcessService} from '../../services/process.service'
import {first, switchMap, forkJoin, of} from 'rxjs'
import {EmailService, IVerificationResponse, IVerifyCodeResponse} from '../../services/email.service'
import {FormControl, Validators} from '@angular/forms'
import {Router, ActivatedRoute, ParamMap} from '@angular/router'
import {Process} from '../../model/process'

@Component({
  selector: 'jhc-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {

  public checking: boolean = true

  public email: string = 'example@example.com'

  public input: FormControl =
    new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.pattern('[A-Za-z]{6}')]
    })

  /**
   * The url is received from backend for verification, we append the code
   */
  private url: string = ''

  /**
   * Needed to be able to continue on the existing process.
   */
  private processId: string = ''

  /**
   *
   */
  private emailHash: string = ''

  /**
   * This is what we append to and go to when we have a successful verification
   */
  private verifyPath: string[] = ['/', 'verify', 'verify']

  constructor(
    private processService: ProcessService,
    private emailService: EmailService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  public ngOnInit(): void {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        return this.processService.getCase(params.get('processId') as string)
      }),
      switchMap((process: Process) => {
        this.email = process.sender
        this.processId = process.processId
        this.emailHash = process.senderHash as string
        return forkJoin([this.emailService.createVerification(this.email, this.processId), of(process)])
      })
    )
      .subscribe({
        next: (res: [IVerificationResponse, Process]) => {
          this.url = res[0].url
          res[1].verifiedSender = res[0].verified
          if (res[1].verifiedSender) {
            this.verifyPath.push(...[res[1].processId, res[1].senderHash as string])
            this.router.navigate(this.verifyPath).then()
          }
          this.processService.case$.next(res[1])
          this.checking = false
        }
      })
  }

  public verify(): void {
    let verified = false
    this.emailService.verifyCode(`${this.url}${this.input.value}`)
      .pipe(
        switchMap((res: IVerifyCodeResponse) => {
          verified = res.verified
          return this.processService.case$
        }),
        first()
      )
      .subscribe({
        next: (process: Process) => {
          process.verifiedSender = verified
          this.processService.case$.next(process)
          this.verifyPath.push(...[process.processId, this.emailHash])
          this.router.navigate(this.verifyPath).then()
        }
      })
  }


  /**
   * Request a new link.
   */
  public send(): void {
    this.emailService.createVerification(this.email, this.processId).subscribe({
      next: (res: IVerificationResponse) => {
        this.url = res.url
      }
    })
  }
}
