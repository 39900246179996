import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {environment} from '../../environments/environment'

export interface IVerificationResponse {
  /**
   * The super secret hash, for some reason
   */
  hash: string

  /**
   * The originating email
   */
  email: string

  /**
   * The URL to verify with ex <code>
   */
  url: string

  /**
   * It can be pre verified
   */
  verified: boolean
}

export interface IVerifyCodeResponse {
  /**
   * A simple yes or no.
   */
  verified: boolean
}

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient) {
  }

  public createVerification(email: string, processId: string): Observable<IVerificationResponse> {
    const url = `${environment.emailUrl}/verify`
    return this.http.put<IVerificationResponse>(url, {email, processId})
  }

  public verifyCode(url: string): Observable<IVerifyCodeResponse> {
    return this.http.put<IVerifyCodeResponse>(url, {})
  }
}
