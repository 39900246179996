<div class="holder">
  <div class="document-holder">
    <div (click)="location.back()" class="close">
      <mat-icon class="material-symbols-sharp">close</mat-icon>
    </div>
    <div class="document">
      <div [innerHTML]="content | safe"></div>
    </div>
    <div class="gradient"></div>
  </div>
</div>
