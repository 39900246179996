<div *ngIf="!checking" class="holder">
  <h2>Verifieringskod</h2>
  <input [formControl]="input" placeholder="">
  <div>Vi har skickat en verifieringskod till</div>
  <div><span class="email">{{email}}</span></div>
  <div>för att kontrollera att du verkligen är du.</div>
  <a (click)="send()">Fick du inget mail?</a>
  <button (click)="verify()" [disabled]="input.invalid" color="primary" mat-raised-button>Verifiera</button>
</div>

<jhc-checking *ngIf="checking" class="holder"></jhc-checking>
