import {Injectable} from '@angular/core'
import {ReplaySubject, Observable, first, switchMap, map} from 'rxjs'
import {Process, IProcess, IRecipient} from '../model/process'
import {BootstrapService, IConditions} from './bootstrap.service'
import {HttpClient} from '@angular/common/http'
import {environment} from '../../environments/environment'
import {MatRadioChange} from '@angular/material/radio'

export interface IEvidenceResult {
  signedUrl: string
}

@Injectable({
  providedIn: 'root'
})
export class ProcessService {

  public case$: ReplaySubject<Process> = new ReplaySubject<Process>(1)

  private conditions: IConditions = {} as any

  constructor(
    private http: HttpClient,
    private bootstrapService: BootstrapService) {
    this.bootstrapService.getProcess().subscribe({
      next: (process: Process) => {
        if (process.packageId) {
          this.case$.next(process)
        }
      }
    })

    this.case$.subscribe({
      next: (process: Process) => {
        this.bootstrapService.saveProcess(process).subscribe()
      }
    })
  }

  public startProcess(): Observable<Process> {
    const url = `${environment.processUrl}/process`
    return this.case$.pipe(
      first(),
      switchMap((process: Process) => {
        let data: any = {
          packageId: process.packageId,
          message: process.message,
          subject: process.subject,
          // Must make a copy of the array
          recipients: process.recipients.map((recipient: IRecipient) => recipient),
          sender: process.sender,
          conditions: this.conditions
        }
        // This might be optional?
        data.recipients.push({email: process.sender})
        return this.http.put<IProcess>(url, data)
      }),
      map((res: IProcess) => new Process(res))
    )
  }

  public getProcess(processId: string, email: string): Observable<Process> {
    const url = `${environment.processUrl}/process/${processId}/${email}`
    return this.http.get<IProcess>(url).pipe(
      map((result: IProcess) => new Process(result))
    )
  }

  public cancelCase(oldProcessId: string, newProcessId: string): void {
    const url = `${environment.processUrl}/case/${oldProcessId}`
    this.http.put<any>(url, {processId: newProcessId}).subscribe()
  }

  public createCase(): Observable<IProcess> {
    const url = `${environment.processUrl}/case`
    return this.case$.pipe(
      first(),
      switchMap((process: Process) => {
        return this.http.put<IProcess>(url, process)
      })
    )
  }

  public getCase(caseId: string): Observable<Process> {
    const url = `${environment.processUrl}/case/${caseId}`
    return this.http.get<IProcess>(url).pipe(
      map((result: IProcess) => new Process(result))
    )
  }

  public sign(processId: string, email: string, signed: boolean): Observable<Process> {
    const body = {
      processId,
      email,
      signed,
      conditions: this.conditions
    }

    const url = `${environment.processUrl}/process/${processId}/${email}`
    return this.http.put<IProcess>(url, body).pipe(
      map((result: IProcess) => new Process(result))
    )
  }

  public getEvidence(processId: string, email: string): Observable<IEvidenceResult> {
    const url = `${environment.processUrl}/evidence/${processId}/${email}`
    return this.http.get<IEvidenceResult>(url)
  }

  public setConditions(event: MatRadioChange): void {
    this.conditions = {accepted: true, type: event.value, email: undefined}
    this.bootstrapService.conditions$.next(this.conditions)
  }

  public resetConditions(): void {
    this.conditions = {accepted: false, type: undefined, email: undefined}
    this.bootstrapService.conditions$.next(this.conditions as IConditions)
  }
}
