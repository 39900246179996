import {AfterViewInit, Component, ElementRef, Renderer2, ViewChild} from '@angular/core'
import {ImageService} from '../services/image.service'

@Component({
  selector: 'jhc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit{

  public imageUrl: string = ''

  @ViewChild('backgroundImage') private backgroundHolder: ElementRef = new ElementRef<any>(undefined)

  constructor(
    private imageService: ImageService,
    private renderer: Renderer2
  ) {
  }

  public ngAfterViewInit(): void {
    const images = [
      'seal_bg_1.jpg',
      'seal_bg_2.jpg',
      'seal_bg_3.jpg',
      'seal_bg_4.jpg',
      'seal_bg_5.jpg',
      'seal_bg_7.jpg',
      'seal_bg_8.jpg',
      'seal_bg_9.jpg',
      'seal_bg_10.jpg',
      'seal_bg_11.jpg',
      'seal_bg_12.jpg',
      'seal_bg_13.jpg',
      'seal_bg_14.jpg',
      'seal_bg_15.jpg'
    ]

    const randomElement = images[Math.floor(Math.random() * images.length)]
    this.imageService.getImage(randomElement).subscribe({
      next: (url: string) => {
        this.imageUrl = `url(${url})`
        this.renderer.setStyle(this.backgroundHolder.nativeElement, 'background-image', `${this.imageUrl}`)
        this.renderer.addClass(this.backgroundHolder.nativeElement, 'background-animation')
      }
    })
  }
}
