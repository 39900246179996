import {Component} from '@angular/core'
import {ProgressIndicatorService} from '../../services/progress-indicator.service'

@Component({
  selector: 'jhc-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss']
})

export class ProgressIndicatorComponent {
  constructor(public progressIndicatorService: ProgressIndicatorService) {
  }
}
