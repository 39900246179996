import {Injectable} from '@angular/core'
import {BehaviorSubject} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ProgressIndicatorService {

  public progressRunning$ = new BehaviorSubject(false)
}
