export interface IProcess {
  /**
   * Sender is the person (currently email) that
   * creates this signing case.
   */
  sender: string

  /**
   * The receivers, possibly include self.
   */
  recipients: IRecipient[]

  /**
   * The document package to sign
   */
  packageId: string

  /**
   * Could have a process id if from
   * backend otherwise we set it to ''
   */
  processId: string

  /**
   * Is the sender verified?
   */
  verifiedSender: boolean

  /**
   * Message to be inlcuded with the package
   */
  message: string

  /**
   * The subject line
   */
  subject: string

  /**
   * The documents  array
   */
  documents: any[]

  /**
   * The signatures
   */
  signatures: any[]

  /**
   * Complete
   */
  complete: boolean

  /**
   * Sender hash for immediate signing, it is never saved just
   * returned on create.
   */
  senderHash?: string
}

export interface ISignature {
  /**
   * Date of signature
   */
  date: string // "2022-05-27T11:31:56.834Z"

  /**
   * A unique id of this signature, for whatever reason.
   */
  id: string // "0327b97b-0886-4875-9bfe-9744db018887"

  signed: boolean // true/false

  /**
   * Currently a string but should be an interface
   */
  signee: string // "rex@rexsuecia.com"

  /**
   * Set by server if the requester has signed
   */
  signedByRequester: boolean

  /**
   * Id of the evidence
   */
  evidence: string
}

export interface IRecipient {
  /**
   * We start with email
   */
  email: string
}

export class Process implements IProcess {

  // Must be assigned by backend
  public packageId: string = ''

  public processId: string = ''

  public recipients: IRecipient[] = []

  public sender: string = ''

  public verifiedSender: boolean = false

  public message: string = ''

  public subject: string = ''

  public documents: any[] = []

  public signatures: ISignature[] = []

  public complete: boolean = false

  /**
   * Non interface logics here
   */

  /**
   * This is used so that we do not send emails again
   * if user presses back.
   */
  public started: boolean = false

  /**
   */
  public senderHash: string | undefined

  /**
   * If this is already signed.
   */
  public signedByRequester: boolean = false

  /**
   * If the requester has accepted conditions or nots.
   */
  public acceptedByRequester: boolean = false

  /**
   * Evidence is set by server when all have signed
   */
  public evidence: string = ''

  constructor(process?: IProcess) {
    if (process) {
      Object.assign(this, process)
    }
  }
}
