<ng-container [ngSwitch]="state">
  <div *ngSwitchCase="'confirmed'" class="holder">
    <h2>
      Din e-post adress är verifierad och samtliga e-post adresser du angivit har erhållit instruktioner för
      signering.</h2>
    <button
      [routerLink]="['/', 'sign', process.processId, process.senderHash]"
      color="primary" mat-raised-button>Till signeringen
    </button>
  </div>
  <div *ngSwitchCase="'failed'" class="holder">
    <h2>Tyvärr!</h2>
    <p>Vi kunde inte verifiera din e-post!</p>
    <button
      [routerLink]="['/', 'create', process.processId, {outlets: {'footer': null}}]"
      color="primary" mat-raised-button>Skicka ny kod
    </button>
  </div>
  <div *ngSwitchCase="'waiting'" class="holder">
    <jhc-checking></jhc-checking>
  </div>
</ng-container>
