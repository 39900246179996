import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {CreateComponent} from './0-create/create.component'
import {VerifyComponent} from './1-verify/verify.component'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'create'
  },
  {
    path: '',
    children: [
      {
        path: 'create/:processId',
        component: CreateComponent
      },
      {
        path: 'verify/:processId/:emailHash',
        component: VerifyComponent
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VerifyRoutingModule {
}
