import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'

import {VerifyRoutingModule} from './verify-routing.module'
import {CreateComponent} from './0-create/create.component'
import {VerifyComponent} from './1-verify/verify.component'
import {MatButtonModule} from '@angular/material/button'
import {ReactiveFormsModule} from '@angular/forms'
import {CheckingComponent} from './checking/checking.component'


@NgModule({
  declarations: [
    CreateComponent,
    VerifyComponent,
    CheckingComponent
  ],
  imports: [
    CommonModule,
    VerifyRoutingModule,
    MatButtonModule,
    ReactiveFormsModule
  ],
  exports: [
    CheckingComponent
  ]
})
export class VerifyModule {
}
